import React from 'react';

import _styles from './subheadline.module.scss';

const Subheadline = () => {
  return (
    <>
      <h2 className={`${_styles.subheadline}`}>In the meantime...</h2>
    </>
  );
};

export default Subheadline;
