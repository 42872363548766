import React from 'react';

import _styles from './preheadline.module.scss';

const Preheadline = () => {
  return (
    <>
      <div className={`${_styles.preheadline}`}>
        Thanks For Claiming Your Copy Of The Insider's Guide!
      </div>
      <br />
      <br />
    </>
  );
};

export default Preheadline;
