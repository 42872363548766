import React from 'react';
import PropTypes from 'prop-types';

import { navigate } from 'gatsby';
import { canUseDOM } from 'kalanso-plugin-utils';

const ProtectedRoute = ({ component: Component, test, fallbackRoute, ...componentProps }) => {
  if (!canUseDOM()) {
    return null;
  }

  if (!test) {
    navigate(fallbackRoute);

    return null;
  }

  return <Component {...componentProps} />;
};

ProtectedRoute.propTypes = {
  test: PropTypes.bool,
  fallbackRoute: PropTypes.string,
  component: PropTypes.func.isRequired,
};

ProtectedRoute.defaultProps = {
  test: false,
  fallbackRoute: '/'
};


export default ProtectedRoute;
