import React from 'react';

import { Router } from '@reach/router';

import useAppState from '../components/use-app-state';

import Layout from 'gatsby-theme-kalanso-ui/src/components/Layout';
import ProtectedRoute from '../components/ProtectedRoute';
import Redirecting from '../components/Redirecting';
import InsidersGuideAccessPage from '../components/pages/insiders-guide-access/InsidersGuideAccessPage';

const SuccessPage = () => {
  const { insidersGuideRequested } = useAppState();

  return (
    <Layout>
      <Router basepath="/access">
        <ProtectedRoute
          path="/insiders-guide"
          component={InsidersGuideAccessPage}
          test={insidersGuideRequested}
        />

        {/* Catch All Route */}
        <ProtectedRoute path="/*" component={Redirecting} test={false} />
      </Router>
    </Layout>
  );
};

export default SuccessPage;
