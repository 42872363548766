import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import _styles from './letter.module.scss';

const Letter = () => {
  const imageData = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "survey-link--post--2820x2364.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  return (
    <>
      <p className={`${_styles.paragraph}`}>
        Hey, my name is Pat and I want to thank you so much for claiming your
        copy of{' '}
        <strong>
          The Insider's Guide To Creating Robust Soil Outdoor Gardeners Dream
          Of!
        </strong>
      </p>
      <p className={`${_styles.paragraph}`}>
        This new Free Report explains the five biggest hurdles every Outdoor
        Gardener faces AND how to overcome them.
      </p>
      <p className={`${_styles.paragraph}`}>
        <strong>TIP #1</strong> will give you one incredibly important piece of
        soil data you don't want to miss.
      </p>
      <p className={`${_styles.paragraph}`}>
        <strong>TIP #2</strong> reveals a clever way to create low-maintenance,
        balanced soil.
      </p>
      <p className={`${_styles.paragraph}`}>
        <strong>TIP #3</strong> will give you an beautifully simple tip that
        could improve your soil immediately.
      </p>
      <p className={`${_styles.paragraph}`}>
        <strong>TIP #4</strong> will let you in on an all-to-common unfortunate
        scenario and what to do about it.
      </p>
      <p className={`${_styles.paragraph}`}>
        <strong>And TIP #5</strong> helps you find the perfect soil/plant match
        made in heaven!
      </p>
      <p className={`${_styles.paragraph}`}>
        Gardening can be overwhelming at times. And{' '}
        <strong>The Insider's Guide</strong> is meant to help you turn off the
        overwhelm and just focus on what matters no matter what zone you're in.
      </p>
      <p className={`${_styles.paragraph}`}>
        But before you go to your email inbox to get your copy...
      </p>

      <p className={`${_styles.paragraph}`}>I could really use your help.</p>
      <p className={`${_styles.paragraph}`}>
        I’m putting a program together that could really help you eliminate the
        overwhelm once and for all.
      </p>
      <p className={`${_styles.paragraph}`}>
        I want to be able to customize the program to YOU and help you overcome
        any obstacle or roadblock you might be experiencing right now.
      </p>
      <p className={`${_styles.paragraph}`}>
        So if you could answer this one question for me, that would be awesome!
      </p>
      <p className={`${_styles.paragraph}`}>Here goes…</p>
      <br />
      <a
        href={
          'https://docs.google.com/forms/d/e/1FAIpQLSdB2J537hJ3WTBI5Wzs9u1JmkWGSt6kxTGozBEClyyq4R_x6w/viewform?usp=pp_url'
        }
        className="js-survey-link"
      >
        <Img
          alt="What's Your Number #1 Biggest Challenge When It Comes To Your Soil?"
          fluid={imageData.file.childImageSharp.fluid}
        />
      </a>
      <br />
      <p className={`${_styles.paragraph}`}>
        The more specific you are, the better I can serve you.
      </p>
      <p className={`${_styles.paragraph}`}>Just click big yellow button.</p>
      <p className={`${_styles.paragraph}`}>
        Thanks SO much. I really appreciate this.
      </p>
      <p className={`${_styles.paragraph}`}>Here's to growing your soil.</p>
      <p className={`${_styles.paragraph}`}>Till then,</p>
      <p className={`${_styles.paragraph}`}>Pat White</p>
    </>
  );
};

export default Letter;
