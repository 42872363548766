import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Headline = () => {
  const imageData = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "insiders-guide--2160x1200.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  return (
    <>
      <h1>Keep an eye on your email inbox over the next few minutes.</h1>
      <br/>
      <p style={{ textAlign: 'center' }}>(that's where we're sending it)</p>

      <Img
        alt="The Insider's Guide"
        fluid={imageData.file.childImageSharp.fluid}
      />
    </>
  );
};

export default Headline;
