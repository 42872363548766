import React from 'react';

// import Layout from 'gatsby-theme-kalanso-ui/src/components/Layout';
import SEO from 'gatsby-theme-kalanso-ui/src/components/SEO';

import Container from 'gatsby-theme-kalanso-ui/src/components/layout/Container';
import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import Preheadline from './Preheadline';
import Subheadline from './Subheadline';
import Headline from './Headline';
import Letter from './Letter';

import FooterSection from '../../shared/FooterSection';
import FooterBody from '../../shared/FooterBody';

import _styles from './page.module.scss';

const InsidersGuideAccessPage = () => {
  return (
    <>
      <SEO title={'You Made A Great Decision!'} hideFromSEO={true} />
      <Container fullScreen={true}>
        <Row justify="Center" className={`${_styles.preheadlineRow}`}>
          <Col>
            <Preheadline />
          </Col>
        </Row>

        <Row justify="Center">
          <Col>
            <Headline />
          </Col>
        </Row>

        <Row justify="Center">
          <Col>
            <Subheadline />
          </Col>
        </Row>

        <Row justify="Center" className={`${_styles.letterRow}`}>
          <Col>
            <br />
            <br />
            <Letter />
          </Col>
        </Row>
      </Container>

      <FooterSection>
        <FooterBody />
      </FooterSection>
    </>
  );
};

export default InsidersGuideAccessPage;
